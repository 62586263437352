import {prepare_post_load} from 'components/common_components'
import {add_listener_once} from 'lib/interactions'

export download_updated_task_list = (id) ->
  fetch("/admin/analyst_tasks/#{id}/outstanding").then (response) -> response.text().then (html) ->
    document.querySelector('#publish_series_form').innerHTML = html
    prepare_post_load()

export add_listener_to_confidence_level_buttons = ->
  [].map.call document.querySelectorAll('.confidence_score .score'), (el) -> add_listener_once(el, -> add_button_listener(el))

add_button_listener = (el) ->
  el.addEventListener 'click', (e) ->
    e.preventDefault()
    score.classList.remove('selected') for score in document.querySelectorAll('.confidence_score .score.selected')
    el.classList.add 'selected'
    document.querySelector('#dividend_series_confidence_score').value = el.dataset.value