import {MDCRipple} from '@material/ripple'
import {MDCTextField} from '@material/textfield'
import {MDCTabBar} from '@material/tab-bar'
import {MDCTabIndicator} from '@material/tab-indicator'
import {MDCCheckbox} from '@material/checkbox'
import TomSelect from 'tom-select'
import {DateTime} from "luxon"
import {add_date_pickers} from 'components/date_picker'
import {params_with_csrf_token} from 'components/forms'
import {find_parent_element} from 'lib/dom_search'
import {xhr} from 'lib/requests'
import {prepare_form_listeners, add_listener_once} from 'lib/interactions'
import {add_listener_to_confidence_level_buttons} from 'publish_series'
import {add_dividend_series_graph} from 'components/dividend_series_graph'
import {add_share_price_button_listener} from 'components/tabs'
import {prepare_date_rules} from 'date_rules'
import {prepare_dividend_series_mananger} from 'components/dividend_series_manager'
import {prepare_dividend_manager} from 'components/security_dividends_manager'
import {prepare_security} from 'security'
import {prepare_report_issue_form} from 'components/report_issue_form'
import {prepare_header_menu} from 'components/top_app_bar'
import {FlashMessage} from 'components/flash'

selector = '.mdc-button, .mdc-icon-button, .mdc-card__primary-action'
[].map.call document.querySelectorAll(selector),              (el) -> new MDCRipple(el)
[].map.call document.querySelectorAll('.mdc-text-field'),     (el) -> new MDCTextField(el)
[].map.call document.querySelectorAll('.mdc-tab-indicator'),  (el) -> new MDCTabIndicator(el)
[].map.call document.querySelectorAll('.mdc-checkbox'),       (el) -> new MDCCheckbox(el)
[].map.call document.querySelectorAll('header .mdc-tab-bar'), (el) -> new MDCTabBar(el)

prepare_load_on_select = (element) ->
  select = new mdc.select.MDCSelect(element)
  select.listen 'MDCSelect:change', ->
    url = element.dataset.url.replaceAll('{{selection}}', select.value)
    document.location = url

[].map.call document.querySelectorAll('.mdc-select.load-on-select'), (el) -> prepare_load_on_select(el)

export tom_selected_elements = []

export prepare_selects = ->
  [].map.call document.querySelectorAll('select.select2'), (el) -> 
    unless el.classList.contains 'tomselected'
      if el.classList.contains 'select2-multiple'
        tom_selected_elements.push(new TomSelect(el, {maxOptions: null, maxItems: null, hidePlaceholder: true, closeAfterSelect: true, plugins: ['remove_button']}))
      else
        tom_selected_elements.push(new TomSelect(el, {maxOptions: null, maxItems: 1, hidePlaceholder: true, closeAfterSelect: true}))

export remove_selects = ->
  while tom_selected_elements.length > 0
    select = tom_selected_elements.pop()
    select.destroy()

export prepare_post_load = ->
  prepare_selects()
  add_date_pickers()
  prepare_form_listeners()
  add_listener_to_confidence_level_buttons()
  add_dividend_series_graph()
  prepare_date_rules()
  prepare_dividend_series_mananger()
  prepare_dividend_manager()
  prepare_security()
  prepare_report_issue_form()
  prepare_header_menu()

document.addEventListener "DOMContentLoaded", -> prepare_post_load()