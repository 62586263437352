export form_params = (form) ->
  params = new URLSearchParams()
  append_input_to_params(input,  params) for input  in form.querySelectorAll('input')
  append_input_to_params(select, params) for select in form.querySelectorAll('select')
  append_input_to_params(input,  params) for input  in form.querySelectorAll('textarea')
  append_csrf_token(params) unless params.has('authenticity_token')
  params

export form_query_url = (form) ->
  query = form.getAttribute('action') + "?"
  query += input_to_query_string(input)  for input  in form.querySelectorAll('input')
  query += input_to_query_string(select) for select in form.querySelectorAll('select')
  query.substring(0, query.length - 1)

export params_with_csrf_token = ->
  params = new URLSearchParams()
  append_csrf_token(params)
  params

append_input_to_params = (input, params) ->
  if input.type == 'checkbox' and !input.checked
    params.append(input.name, '')
  else
    params.append(input.name, input.value)

input_to_query_string = (input) ->
  if input.name and input.name.length > 0 and input.value and input.value.length > 0
    return input.name + "=" + encodeURIComponent(input.value) + "&"
  ""

append_csrf_token = (params) ->
  token_name  = document.querySelector('meta[name=csrf-param]').getAttribute('content')
  token_value = document.querySelector('meta[name=csrf-token]').getAttribute('content')
  params.append(token_name, token_value)

export form_element = (url, method, id) ->
  form = document.createElement('FORM')
  form.setAttribute('action', url)
  form.setAttribute('method', 'POST')
  form.setAttribute('id', id)
  form.append hidden_field('_method', method) if method != 'POST'
  token_name  = document.querySelector('meta[name=csrf-param]').getAttribute('content')
  token_value = document.querySelector('meta[name=csrf-token]').getAttribute('content')
  form.append hidden_field(token_name, token_value)
  form

export hidden_field = (name, value) ->
  input = document.createElement('INPUT')
  input.setAttribute('name', name)
  input.setAttribute('type', 'hidden')
  input.value = value
  input