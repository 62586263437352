if a = document.querySelector 'a.show-forgotten-password'
  a.addEventListener 'click', (e) ->
    e.preventDefault()
    document.querySelector('#login-form').style = "display:none"
    document.querySelector('#reset-password-form').style = "display:block"

if cancel = document.querySelector '#reset-password-form a.cancel'
  cancel.addEventListener 'click', (e) ->
    e.preventDefault()
    document.querySelector('#login-form').style = "display:block"
    document.querySelector('#reset-password-form').style = "display:none"