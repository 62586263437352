import {xhr} from 'lib/requests'
import {params_with_csrf_token} from 'components/forms'
import {loading_div, loading_div_with_message} from 'components/loading'
import {encode_html} from 'lib/character_encoder'
import {element_with_content} from 'components/common_elements'
import {prepare_post_load} from 'components/common_components'
import {set_analyst_flag_status} from 'analyst_flag_dialog'
import {add_listener_once} from 'lib/interactions'

export class DividendSeriesManager
  
  constructor: ->
    @addListeners()
    @scrollTableTo 'bottom'

  seriesArrowButtonClicked: (a) ->
    a.classList.remove('active')
    @loadSeries a.dataset.id

  loadSeries: (id) ->
    @requestAndSetResponse "/dividend_series/#{id}", 'GET'

  updateDisplayedSeries: (html) ->
    offset = @scrollTop()
    document.querySelector('#dividend-versions').innerHTML = html
    @scrollTableTo(offset)
    prepare_post_load()
    @removeOverlay()

  scrollTop: ->
    scrollDiv = document.querySelector('#dividend-versions .mdc-data-table__table-container')
    if scrollDiv then scrollDiv.scrollTop else 0

  scrollTableTo: (offset) ->
    if scrollDiv = document.querySelector('#dividend-versions .mdc-data-table__table-container')
      if offset == 'bottom'
        if row = scrollDiv.querySelector('tr.status-pay ~ tr.status-est')
          offset = row.offsetTop - 100
        else
          offset = scrollDiv.querySelector('table').offsetHeight
      scrollDiv.scrollTop = offset

  buttonClick: (button) ->
    if button.dataset.confirm
      @showConfirmation(button)
    else
      method = button.dataset.ajaxmethod or 'POST'
      @requestAndSetResponse(button.getAttribute('href'), method, params_with_csrf_token())

  showConfirmation: (button) ->
    Swal.fire(
      title: 'Are you sure?',
      text: button.dataset.confirm,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    ).then (result) => 
      if result.value
        method = button.dataset.ajaxmethod or 'POST'
        @requestAndSetResponse(button.getAttribute('href'), method, params_with_csrf_token())
  
  changeDividends: (a) ->
    unless a.classList.contains 'disabled'
      a.classList.add 'disabled'
      @requestAndSetResponse("/admin/dividend_series?security_id=#{a.dataset.id}", 'POST', params_with_csrf_token())

  securityReviewed: (button) ->
    fetch(button.getAttribute('href'), body: params_with_csrf_token(), method: 'POST').then (response) -> response.json().then (json) ->
      set_analyst_flag_status json

  regenerateDividends: (button) -> 
    @addOverlay loading_div_with_message("Queuing security regenerate task")
    fetch(button.getAttribute('href'), method: 'POST', body: params_with_csrf_token())

  requestAndSetResponse: (url, method, data) ->
    @addOverlay loading_div()
    xhr url, method, data, (response) => response.text().then (html) => @updateDisplayedSeries(html)

  removeOverlay: ->
    overlay = document.querySelector('.overlay')
    overlay.remove() if overlay
    document.querySelector('.tab.active').classList.remove('loading-container')

  addOverlay: (content) ->
    overlay = document.querySelector('.overlay')
    overlay.remove() if overlay
    tab = document.querySelector('.tab.active')
    tab.classList.add('loading-container')
    tab.append content

  @showAddDividendForm: ->
    document.querySelector('#dividend-buttons').classList.add('hidden')
    form = document.querySelector('form.new_dividend_instance')
    form.classList.add 'show'
    form.scrollIntoView()

  hideAddDividendForm: ->
    document.querySelector('#dividend-buttons').classList.remove('hidden')
    document.querySelector('form.new_dividend_instance').classList.remove('show')

  addListeners: ->
    manager = this
    [].map.call document.querySelectorAll('form#toggle-series span.active'), (el) -> add_listener_once el, (el) ->
      el.addEventListener 'click', -> manager.seriesArrowButtonClicked(el)
    [].map.call document.querySelectorAll('form#toggle-series select'), (el) -> add_listener_once el, (el) ->
      el.addEventListener 'change', -> manager.loadSeries(el.value)
    [].map.call document.querySelectorAll('a.post-and-set-response'), (el) -> add_listener_once el, (el) ->
      el.addEventListener 'click', (e) ->
        e.preventDefault()
        manager.buttonClick(el) unless el.classList.contains('disabled')
    [].map.call document.querySelectorAll('a.add-dividend'), (el) -> add_listener_once el, (el) ->
      el.addEventListener 'click', (e) ->
        e.preventDefault()
        DividendSeriesManager.showAddDividendForm()
    [].map.call document.querySelectorAll('a.cancel-add-dividend'), (el) -> add_listener_once el, (el) ->
      el.addEventListener 'click', -> manager.hideAddDividendForm()
    [].map.call document.querySelectorAll('a.change-dividends'), (el) -> add_listener_once el, (el) ->
      el.addEventListener 'click', (e) ->
        e.preventDefault()
        manager.changeDividends(el)
    [].map.call document.querySelectorAll('#dividend-buttons a.reviewed'), (el) -> add_listener_once el, (el) ->
      el.addEventListener 'click', (e) ->
        e.preventDefault()
        manager.securityReviewed(el)
    [].map.call document.querySelectorAll('#dividend-buttons a.regenerate-dividends'), (el) -> add_listener_once el, (el) ->
      el.addEventListener 'click', (e) ->
        e.preventDefault()
        manager.regenerateDividends(el)

export prepare_dividend_series_mananger = ->
  if document.querySelector('#dividend-versions')
    window.series_manager = new DividendSeriesManager()