import {add_listener_once} from 'lib/interactions'

import {Chart, BarElement, LinearScale, CategoryScale, PointElement, BarController, Filler, Legend, Title, Tooltip } from 'chart.js'
Chart.register(BarElement, LinearScale, CategoryScale, PointElement, BarController, Filler, Legend, Title, Tooltip)

class DividendSeriesGraph
  constructor: (div) ->
    @div = div
    id = div.dataset.id
    @downloadAndRenderGraph(id) if id

  downloadAndRenderGraph: (id) ->
    fetch("/admin/dividend_series/#{id}/graph.json").then (response) => response.json().then (json) =>
      @dividendsGraph(json['d']['v'], json['d']['l'], json['d']['t'])

  dividendsGraph: (v,l,t,h) ->
    ctx = @div.getContext('2d')
    new Chart ctx,
      type: 'bar',
      data:
        labels: l
        datasets: v
      options:
        responsive: true
        title:
          display: true
          text: document.querySelector('h1').innerText
          fontColor: '#3c62ec'
          fontFamily: "'Lato', 'sans-serif'"
          fontSize: 14
        scales:
          x:
            stacked: true
          y:
            stacked: true
            ticks:
              beginAtZero: true
        tooltips: 
          callbacks:
            label: (item, data) ->
              t[item.index][item.datasetIndex]

export add_dividend_series_graph = ->
  [].map.call document.querySelectorAll('.dividend-series-graph'), (el) -> add_listener_once(el, (el) -> new DividendSeriesGraph(el))