import {User} from 'lib/user'
import {params_with_csrf_token} from 'components/forms'
import {find_parent_element} from 'lib/dom_search'
import {DividendSeriesManager} from 'components/dividend_series_manager'

# Class to control interactions on admin view of dividends (DividendInstance) table. 
class SecurityDividendsManager
  
  constructor: ->
    @table = document.querySelector('table#share-dividends')
    @addListeners()

  postDividendUpdate: (id, field, value, callback) ->
    values = params_with_csrf_token()
    values.append('_method', 'PUT')
    values.append("dividend_instance[#{field}]", value)
    fetch("/admin/dividend_instances/#{id}.json", method: 'PUT', body: values).then (response) => response.json().then (json) =>
      callback(json)

  submitValue: (input) ->
    row = find_parent_element(input, 'TR')
    id = row.dataset.id
    @postDividendUpdate id, input.getAttribute('name'), input.value, (json) => @setUpdatedDividendValues(row, json)

  setUpdatedDividendValues: (row, json) ->
    row.querySelector('.freq').innerText = "#{json.frequency} #{json.position}"
    row.querySelector('.flag').innerText = json.flag
    row.querySelector('.amt').innerText  =  json.display_amount
    row.querySelector('.src').innerText  = json.source
    row.querySelector('.year').innerText = json.fiscal_year
    row.setAttribute('class', json.row_class)
    row.classList.add 'mdc-data-table__row', 'dividend-row'
    @show_publish_button()

  submitAmount: (link) ->
    input = find_parent_element(link, 'TD')
    input = input.querySelector('input')
    @submitValue input

  amountKeyPress: (e, input) ->
    if e.keyCode == 13
      e.preventDefault()
      @submitValue input

  editDividendAmountClicked: (a) ->
    row = find_parent_element(a, 'TR')
    row.classList.toggle 'edit-mode'
    amt = row.querySelector('.amount')
    amt.focus()
    amt.setSelectionRange(0, amt.value.length)

  copyDividendClicked: (a) ->
    row = find_parent_element(a, 'TR')
    DividendSeriesManager.showAddDividendForm()
    fetch("/admin/dividend_instances/#{row.dataset.id}/clone").then (response) -> response.json().then (json) ->
      document.querySelector('form.new_dividend_instance select#dividend_instance_fiscal_year').value = json.fiscal_year
      document.querySelector('form.new_dividend_instance #dividend_instance_declaration_date').value = json.declaration_date
      document.querySelector('form.new_dividend_instance #dividend_instance_ex_div_date').value = json.ex_div_date
      document.querySelector('form.new_dividend_instance #dividend_instance_record_date').value = json.record_date
      document.querySelector('form.new_dividend_instance #dividend_instance_pay_date').value = json.pay_date
      document.querySelector('form.new_dividend_instance #dividend_instance_year_end_date').value = json.year_end_date
      document.querySelector('form.new_dividend_instance #dividend_instance_period_end_date').value = json.period_end_date
      document.querySelector('form.new_dividend_instance #dividend_instance_amount').value = json.amount
      document.querySelector('form.new_dividend_instance #dividend_instance_currency_code').value = json.currency_code
      document.querySelector('form.new_dividend_instance #dividend_instance_frequency').value = json.frequency
      document.querySelector('form.new_dividend_instance #dividend_instance_flag').value = json.flag

  toggleShowAdjustedAmounts: ->
    @table.classList.toggle 'show-adjusted'
    icon = if @table.classList.contains('show-adjusted') then 'toggle_on' else 'toggle_off'
    document.querySelector('table#share-dividends .display-adjusted i').innerText = icon

  ALL_CLASSES = ['flagG','flagN','flagR','flagS','flagU','freq1','freq5','freq8','year2010','year2011','year2012','year2013','year2014','year2015','year2016','year2017','year2018','year2019','year2020','year2021','year2022','year2023','year2024','year2025','year2026','year2027','year2028','freq51','freq52','freq53','freq54','freq41','freq42','freq43','freq44','freq45','freq46','freq47','freq48','freq61','freq62','freq63','freq64','freq65','freq66','freq21','freq22','freq23','freq24','freq25','freq26','freq27','freq28','freq29','freq210','freq211','freq212','freq213','freq214','freq215','freq216','freq217','freq218','freq219','freq220','freq221','freq222','freq223','freq224','freq71','freq72','freq73','freq74','freq31','freq32','freq33','freq34','freq35','freq36','freq37','freq38','freq39','freq310','freq311','freq312']

  dividendRowClicked: (tr) ->
    @clearTableClasses()
    clicked = tr.classList.contains('clicked')
    [].map.call document.querySelectorAll('#share-dividends tr'), (el) -> el.classList.remove 'clicked'
    if !clicked
      tr.classList.add 'clicked'
      @table.classList.add(clz) for clz in ALL_CLASSES when tr.classList.contains(clz)

  clearTableClasses: ->
    @table.classList.remove(clz) for clz in ALL_CLASSES

  show_publish_button: ->
    el = document.querySelector('.publish-draft-button')
    el.classList.remove 'hidden' if el

  addListeners: ->
    manager = this
    [].map.call document.querySelectorAll('#share-dividends a.edit-complete'), (el) ->
      el.addEventListener 'click', (e) ->
        e.preventDefault()
        manager.submitAmount(el)      # dividend amount set by clicking the check icon

    [].map.call document.querySelectorAll('#share-dividends .amount'), (el) ->
      el.addEventListener 'keypress', (e) -> manager.amountKeyPress(e, el) # dividend amount input keypress (may be enter to submit)

    [].map.call document.querySelectorAll('#share-dividends select'), (el) ->
      el.addEventListener 'change', (e) ->
        manager.submitValue(el)       # dividend flag, frequency or fiscal year changed
        find_parent_element(el, 'TR').classList.remove 'hovered'

    [].map.call document.querySelectorAll('#share-dividends a.edit-amount'), (el) ->
      el.addEventListener 'click', (e) -> 
        e.preventDefault()
        manager.editDividendAmountClicked(el)

    [].map.call document.querySelectorAll('#share-dividends a.copy-dividend'), (el) ->
      el.addEventListener 'click', (e) -> 
        e.preventDefault()
        manager.copyDividendClicked(el)

    [].map.call document.querySelectorAll('#share-dividends a.display-adjusted'), (el) ->
      el.addEventListener 'click', (e) ->
        e.preventDefault()
        manager.toggleShowAdjustedAmounts()

    [].map.call document.querySelectorAll('#share-dividends tr select'), (el) ->
      el.addEventListener 'click', (e) ->
        find_parent_element(el, 'TR').classList.add 'hovered'

    [].map.call document.querySelectorAll('#share-dividends tr'), (el) ->
      el.addEventListener 'click', (e) ->
        unless e.target.tagName == 'SELECT' or e.target.tagName == 'OPTION'
          manager.dividendRowClicked(el)

export prepare_dividend_manager = ->
  if User.isAdmin() and document.querySelector('#share-dividends')
    new SecurityDividendsManager()