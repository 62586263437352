import {element_with_content, element_with_class} from 'components/common_elements'

export show_loading_div = (element = null) ->
  (element or document.body).append loading_div()
  
export loading_div = ->
  el = element_with_content('div', element_with_class('div', 'overlay-opacity'), 'loading-tab', 'overlay')
  el.append loadingAnimation()
  el

export loading_div_with_message = (message) ->
  el = loading_div()
  el.append element_with_content('div', message, 'status-message')
  el

export loading_icon = ->
  element_with_content('i', '&#xe863;', "loading-icon", "material-icons", "small", "on")

export updateLoadingMessage = (message) ->
  el = document.querySelector('.overlay .status-message')
  el.innerText = message if el

export loadingAnimation = ->
  element_with_content('div', element_with_content('div', '&#xe863;', 'material-icons', 'loading-icon', 'on'), 'loading-icon-wrap')