import {element_with_class, element_with_content} from 'components/common_elements'
import {add_listener_once} from 'lib/interactions'

export prepare_date_rules = ->
  [].map.call document.querySelectorAll('.query-rules'), (el) -> add_listener_once(el, -> add_query_rules_click_listener(el))

add_query_rules_click_listener = (el) ->
  el.addEventListener 'click', (e) ->
    e.preventDefault()
    download_date_rules(el.dataset)

download_date_rules = (data) ->
  url = "/admin/date_rules/#{data.security}?dividendType=#{data.dividendType}&position=#{data.position}&dateType=#{data.dateType}"
  fetch(url).then (response) -> response.json().then (json) -> show_date_rules(json)

show_date_rules = (json) ->
  [].map.call document.querySelectorAll('.popup-date-rules'), (el) -> el.remove()
  div = element_with_content('div', rule_table(json), 'popup-date-rules')
  div.addEventListener 'click', -> div.remove()
  document.body.append div

rule_table = (rules) ->
  table = element_with_content('table', table_head(), 'mdc-data-table__table')
  div = element_with_content('div', element_with_content('div', table, 'mdc-data-table__table-container'), 'mdc-data-table')
  body = element_with_class('tbody', 'mdc-data-table__content')
  body.append date_rule_row(row) for row in rules
  table.append(body)
  div

table_head = ->
  tr = element_with_class('tr', 'mdc-data-table__header-row')
  tr.append element_with_content('th', 'Rule', 'mdc-data-table__header-cell')
  tr.append element_with_content('th', 'Matches', 'mdc-data-table__header-cell')
  tr.append element_with_content('th', 'Confidence', 'mdc-data-table__header-cell')
  thead = element_with_content('thead', tr)
  thead

date_rule_row = (row) ->
  tr = element_with_class('tr', 'mdc-data-table__row')
  tr.append element_with_content('td', row.desc, 'mdc-data-table__cell')
  tr.append element_with_content('td', "#{row.matches} of #{row.total}", 'mdc-data-table__cell')
  tr.append element_with_content('td', "#{row.confidence}%", 'mdc-data-table__cell')
  tr