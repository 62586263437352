import {element_with_class, element_with_content} from 'components/common_elements'

export class Popup

  buildFromObject: (object) ->
    @buildFromContent @contentFromObject(JSON.parse(object))

  buildFromContent: (@content) ->
    @removePopup()
    document.body.append @buildPopup()

  contentFromObject: (json) ->
    list = document.createElement('DL')
    list.append @listItem(key, json[key]) for key in Object.keys(json)
    list

  buildPopup: ->
    el = element_with_class('div', 'overlay')
    el.append @opacity()
    el.append element_with_content('div', @content, 'overlay-content')
    el

  opacity: ->
    div = element_with_class('div', 'overlay-opacity')
    div.addEventListener 'click', => @removePopup()
    div

  removePopup: ->
    if el = document.querySelector('.overlay')
      el.remove()

  listItem: (key, val, indent = 0) ->
    val = @parseDate(val) if val && key.slice(-3) == "_at"
    el = element_with_class('div', "indent-#{indent}")
    el.append element_with_content('dt', key)
    el.append element_with_content('dd', @displayVal(val, indent))
    el

  displayVal: (val, indent) ->
    if val instanceof Object
      list = document.createElement('DL')
      list.append @listItem(key, val[key], indent + 1) for key in Object.keys(val)
      return list
    else if val instanceof Array
      res = val.map((el) => @displayVal(el)).join(", ")
      return res
    else
      return val

  parseDate: (val) ->
    d = new Date()
    d.setTime(val * 1000)
    d