import {MDCRipple} from '@material/ripple'
import {MDCTopAppBar} from '@material/top-app-bar'
import {MDCList} from "@material/list"
import {MDCDrawer} from "@material/drawer"
import {add_listener_once} from 'lib/interactions'

export prepare_header_menu = ->
  main = document.querySelector('.main-content')
  return unless main

  add_listener_once main, (main) ->

    topAppBar = MDCTopAppBar.attachTo(document.querySelector('#app-bar'))
    topAppBar.setScrollTarget(main)

    if document.querySelector('.side-menu')
      drawer = MDCDrawer.attachTo(document.querySelector('.side-menu'))
      topAppBar.listen 'MDCTopAppBar:nav', => drawer.open = !drawer.open
      list = document.querySelector('.side-menu .mdc-deprecated-list')
      list.addEventListener 'click', (event) => drawer.open = false
      document.body.addEventListener 'MDCDrawer:closed', () => main.querySelector('input, button').focus()

[].map.call document.querySelectorAll('.drop-down-menu .mdc-list-item'), (el) -> add_listener_once el, (el) ->
  new MDCRipple(el)