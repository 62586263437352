import {xhr} from 'lib/requests'
import {FlashMessage} from 'components/flash'
import {reload_issues_tab, reload_dividends_tab} from 'components/tabs'
import {loading_div} from 'components/loading'
import {show_loading_div} from 'components/loading'
import {set_analyst_flag_status} from 'analyst_flag_dialog'
import {add_listener_once} from 'lib/interactions'

export prepare_security = ->
  [].map.call document.querySelectorAll('a.task-panel-run-checks'),               (el) -> add_listener_once(el, (el) -> add_run_checks_button_listener(el))
  [].map.call document.querySelectorAll('a.task-panel-change-dividends'),         (el) -> add_listener_once(el, (el) -> add_change_panel_button_listener(el))
  [].map.call document.querySelectorAll('table#share-dividends.client-view tr'),  (el) -> add_listener_once(el, (el) -> add_dividend_table_client_view_click_listener(el))
  [].map.call document.querySelectorAll('a.security-reviewed-button'),            (el) -> add_listener_once(el, (el) -> add_security_reviewed_button_listener(el))
  [].map.call document.querySelectorAll('.show-add-price-form'),                  (el) -> add_listener_once(el, (el) -> add_new_share_price_button_listener(el))
  [].map.call document.querySelectorAll('.analyst-note .content'),                (el) -> add_listener_once(el, (el) -> toggle_note_expanded(el))
  [].map.call document.querySelectorAll('textarea#analyst_note_content'),         (el) -> add_listener_once(el, (el) -> expand_note_input_box(el))

add_run_checks_button_listener = (el) ->
  el.addEventListener 'click', (e) ->
    e.preventDefault()
    FlashMessage.success "Queuing analyst tasks"
    xhr el.getAttribute('href'), 'POST', null, -> 
      reload_issues_tab()
      setTimeout reload_issues_tab, 2000


add_change_panel_button_listener = (el) ->
  el.addEventListener 'click', (e) ->
    e.preventDefault()
    FlashMessage.success "Creating draft series"
    xhr el.getAttribute('href'), 'POST', null, -> reload_dividends_tab()


add_dividend_table_client_view_click_listener = (el) ->
  el.addEventListener 'click', (e) ->
    e.preventDefault()
    selector = "table#share-dividends tr.d#{el.dataset.dividend}"
    if el.classList.contains 'expanded'
      tr.classList.remove('expanded') for tr in document.querySelectorAll(selector)
    else
      tr.classList.add('expanded') for tr in document.querySelectorAll(selector)


add_security_reviewed_button_listener = (el) ->
  el.addEventListener 'click', (e) ->
    e.preventDefault()
    xhr el.getAttribute('href'), 'POST', null, (response) -> response.json().then (json) ->
      set_analyst_flag_status json


add_new_share_price_button_listener = (el) ->
  el.addEventListener 'click', -> document.querySelector('#new_share_price').classList.remove 'hidden'

toggle_note_expanded = (el) ->
  el.addEventListener 'click', -> el.classList.toggle('expanded')

expand_note_input_box = (el) ->
  el.addEventListener 'focus', -> el.classList.add('expanded')