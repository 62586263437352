import {params_with_csrf_token, hidden_field, form_params, form_query_url} from 'components/forms'
import {xhr} from 'lib/requests'
import {FlashMessage} from 'components/flash'
import {find_parent_element} from 'lib/dom_search'
import {prepare_selects, prepare_post_load} from 'components/common_components'
import {add_date_pickers} from 'components/date_picker'
import {loading_div} from 'components/loading'

######################################################
####################### POSTS ########################

perform_form_submit = (url, method) ->
  form = document.createElement('FORM')
  form.setAttribute('method', 'POST')
  form.setAttribute('action', url)
  token_name  = document.querySelector('meta[name=csrf-param]').getAttribute('content')
  token_value = document.querySelector('meta[name=csrf-token]').getAttribute('content')    
  form.append hidden_field(token_name, token_value)
  form.append hidden_field('_method', method) if method != 'POST'
  document.body.append form
  form.submit()

remote_post = (url, el) ->
  xhr url, 'POST', params_with_csrf_token(), (request) ->
    request.json().then (json) ->
      message = json.message or "Success"
      FlashMessage.success message

perform_post = (url, el) ->
  if el.classList.contains 'append-form'
    perform_form_submit(url, 'POST')
  else
    remote_post(url, el)

  if el.classList.contains 'remove-on-click'
    find_parent_element(el, 'TR').remove()

confirm_post = (url, el) ->
  Swal.fire(
    title: 'Are you sure?',
    text: el.dataset.confirm,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes'
  ).then (result) -> 
    if result.value
      perform_post(url, el)

post_link_clicked = (url, el) ->
  if el.dataset.confirm
    confirm_post(url, el)
  else
    perform_post(url, el)

######################################################
####################### POSTS ########################

remote_put = (url, el) ->
  params = params_with_csrf_token()
  params.append('_method', 'PUT')
  xhr url, 'POST', params, (request) ->
    request.json().then (json) ->
      message = json.message or "Success"
      FlashMessage.success message

put_link_clicked = (url, el) ->
  remote_put(url, el)

  if el.classList.contains 'remove-on-click'
    find_parent_element(el, 'TR').remove()

######################################################
##################### DELETES ########################

remote_delete = (url, el) ->
  xhr url, 'DELETE', null, (response) -> delete_complete(el, response)

delete_complete = (el, response) ->
  if el.dataset.destination
    if dest = document.querySelector(el.dataset.destination)
      response.text().then (html) ->
        dest.innerHTML = html
        prepare_post_load()
  else
    response.json().then (json) ->
      find_parent_element(el, 'TR').remove()
      message = json.message or "Entry removed successfully"
      FlashMessage.success message

perform_delete = (url, el) ->
  if el.classList.contains 'append-form'
    perform_form_submit(url, 'DELETE')
  else
    remote_delete(url, el)

confirm_delete = (url, el) ->
  Swal.fire(
    title: 'Are you sure?',
    text: el.dataset.confirm,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes'
  ).then (result) -> 
    if result.value
      perform_delete(url, el)

delete_link_clicked = (url, el) ->
  if el.dataset.confirm
    confirm_delete(url, el)
  else
    perform_delete(url, el)

######################################################

prepare_post_on_click = (el) ->
  url = el.getAttribute('href')
  el.addEventListener 'click', (e) ->
    e.preventDefault()
    if el.classList.contains'prevent-double-submit'
      return if el.classList.contains 'disabled'
      el.classList.add 'disabled'
    if el.classList.contains 'post-request'
      post_link_clicked(url, el)
    else if el.classList.contains 'put-request'
      put_link_clicked(url, el)
    else
      delete_link_clicked(url, el)

add_change_listener_to_submit_form = (el) ->
  el.addEventListener 'change', ->
    form = find_parent_element(el, 'FORM')
    if form.classList.contains('ajax')
      xhr form.getAttribute('action'), 'POST', form_params(form)
    else
      form.submit()

add_change_listener_to_element = (el) ->
  el.addEventListener 'change', ->
    form = find_parent_element(el, 'FORM')
    if dest = document.querySelector(form.dataset.destination)
      dest.append loading_div()
    fetch(form_query_url(form)).then (response) -> response.text().then (html) ->
      if form.dataset.destination
        if dest = document.querySelector(form.dataset.destination)
          dest.innerHTML = html
          prepare_selects()
          add_date_pickers()
          prepare_form_listeners()

prepare_disable_on_click = (el) ->
  el.addEventListener 'click', (e) ->
    e.preventDefault() if el.classList.contains 'disabled'
    el.classList.add 'disabled'


export prepare_form_listeners = ->
  [].map.call document.querySelectorAll('.disable-on-click'),                            (el) -> add_listener_once(el, -> prepare_disable_on_click(el))
  [].map.call document.querySelectorAll('.post-request, .delete-request, .put-request'), (el) -> add_listener_once(el, -> prepare_post_on_click(el))
  [].map.call document.querySelectorAll('form.submit-on-change select'),                 (el) -> add_listener_once(el, -> add_change_listener_to_submit_form(el))
  [].map.call document.querySelectorAll('form.submit-on-change input[type="checkbox"]'), (el) -> add_listener_once(el, -> add_change_listener_to_submit_form(el))
  [].map.call document.querySelectorAll('form.get-request select'),                      (el) -> add_listener_once(el, -> add_change_listener_to_element(el))

export add_listener_once = (el, add_listener_once_function) ->
  return if el.dataset.addedDynamicListener
  el.dataset.addedDynamicListener = true
  add_listener_once_function(el)
