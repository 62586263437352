export element_with_class = (element, classes...) ->
  el = document.createElement(element.toUpperCase())
  el.classList.add c for c in classes when c isnt null
  el

export element_with_content = (element, content, classes...) ->
  el = element_with_class(element, classes...)
  if typeof(content) == 'string' and content[0] == '&'
    el.innerHTML = content
  else
    el.append content
  el

export link_to = (text, url, classes...) ->
  link = element_with_content('a', text, classes...)
  link.setAttribute('href', url)
  link

export link_to_onclick = (text, onclick, classes...) ->
  link = link_to(text, '#', classes...)
  link.addEventListener 'click', (e) ->
    e.preventDefault()
    onclick()
  link