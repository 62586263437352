import {xhr} from 'lib/requests'
import {form_params} from 'components/forms'
import {element_with_class, element_with_content} from 'components/common_elements'
import {submit_button, link_button} from 'components/material_elements'
import {FlashMessage} from 'components/flash'
import {Popup} from 'components/popup'

issue_popup = null
issue_form = null
issue_button = null

export prepare_report_issue_form = ->
  [].map.call document.querySelectorAll('a.report-issue'), (el) ->
    issue_button = el
    issue_button.addEventListener 'click', (e) ->
      e.preventDefault()
      show_report_issue_form()

show_report_issue_form = ->
  issue_popup = new Popup()
  issue_popup.buildFromContent report_issue_form()

report_issue_form = ->
  issue_form = element_with_class('form', 'report-security-form', 'styled')
  issue_form.append element_with_content('h2', 'Report an issue with this security or a particular task')
  issue_form.append report_issue_input()
  issue_form.append report_issue_buttons()
  issue_form

report_issue_input = ->
  div = element_with_class('div', 'input')
  div.append element_with_content('label', 'Describe the issue')
  div.append report_issue_textarea()
  div

report_issue_textarea = ->
  input = document.createElement('textarea')
  input.setAttribute('name', 'note')
  input.setAttribute('id', 'issue_note')
  input

report_issue_buttons = ->
  div = element_with_class('div', 'buttons')
  div.append report_issue_submit_button()
  div.append report_issue_cancel_button()
  div

report_issue_submit_button = ->
  button = submit_button('Report Issue', true, true)
  button.addEventListener 'click', submit_issue_form
  button

report_issue_cancel_button = ->
  button = link_button('Cancel', '#')
  button.addEventListener 'click', -> issue_popup.removePopup()
  button

submit_issue_form = (e) ->
  e.preventDefault()
  xhr issue_button.getAttribute('href'), 'POST', form_params(issue_form), ->
    FlashMessage.success("Successfully submitted this security for review")
    issue_popup.removePopup()