import {loading_div} from 'components/loading'
import {MDCTabBar} from '@material/tab-bar'
import TomSelect from 'tom-select'
import {xhr} from 'lib/requests'
import {prepare_post_load} from 'components/common_components'

DIVIDENDS_TAB_ID = 1
TASKS_TAB_ID = 2

export reload_issues_tab = ->
  if tab_is_active(TASKS_TAB_ID)
    set_tab_class TASKS_TAB_ID, 'reloading'
    click_tab(TASKS_TAB_ID)

export reload_dividends_tab = ->
  set_tab_class DIVIDENDS_TAB_ID, 'reloading'
  click_tab(DIVIDENDS_TAB_ID)

tabBar = null

click_tab = (tabId) ->
  tabBar.activateTab(tabId - 1)

preprare_tabs = (element) ->
  tabBar = new MDCTabBar(element)
  tabBar.listen 'MDCTabBar:activated', (event) -> tab_clicked(event.detail.index + 1)

tab_clicked = (tabId) ->
  button = tab_button_with_id(tabId)
  url = button.dataset.url
  load_selected_tab(url, tabId)

load_selected_tab = (url, tabId, request_method = 'GET') ->
  set_tab_to_active(tabId)
  if url && url.length > 2 && url[0] != '#'
    window.history.pushState(url, '', url)
    tabContent = tab_with_id(tabId)
    tabContent.append loading_div()
    xhr url, request_method, null, (response) -> response.text().then (html) ->
      if tab_is_active(tabId)
        set_tab_content(tabContent, html)

set_tab_to_active = (tabId) ->
  tab.classList.remove('active') for tab in document.querySelectorAll('.tab')
  tab = tab_with_id(tabId)
  tab.classList.add('active')

tab_is_active = (tabId) ->
  tab_button_has_class tabId, 'mdc-tab--active'

tab_button_has_class = (tabId, claz) ->
  tab_button_with_id(tabId).classList.contains claz

tab_has_class = (tabId, claz) ->
  tab_with_id(tabId).classList.contains claz

set_tab_class = (tabId, claz) ->
  tab_with_id(tabId).classList.add claz

tab_button_with_id = (id) ->
  document.querySelector("#mdc-tab-#{id}")

tab_with_id = (id) ->
  document.querySelector("#tab#{id}")

set_tab_content = (tabContent, html) ->
  tabContent.innerHTML = html
  prepare_post_load()
  tabContent.classList.remove 'reloading'
  scroll_to_tab_top()

export scroll_to_tab_top = -> window.scrollTo(0, document.querySelector('#tab-scroll-anchor').offsetTop - 164)
 
[].map.call document.querySelectorAll('.mdc-tab-bar.interactive'), (el) -> preprare_tabs(el)