import {element_with_class, element_with_content} from 'components/common_elements'
import {MDCSnackbar} from '@material/snackbar'

export class FlashMessage

  @error: (message) -> @addFlashMessage(message, 'flash-error')
  @success: (message) -> @addFlashMessage(message, 'flash-success')

  @addFlashMessage: (message, clazz) ->
    @removeFlashMessage()
    div = @buildFlashMessage(message, clazz)
    document.body.append div
    @prepareFlashMessage()

  @prepareFlashMessage: ->
    div = document.querySelector('.flash-message')
    if div
      snackbar = new MDCSnackbar(div)
      snackbar.open()

  @buildFlashMessage: (message, clazz) ->
    div = element_with_class('div', 'mdc-snackbar', clazz, 'flash-message')
    surface = element_with_class('div', 'mdc-snackbar__surface')
    label = element_with_content('div', message, 'mdc-snackbar__label')
    label.setAttribute('role', 'status')
    label.setAttribute('aria-live', 'polite')
    surface.append label
    div.append surface
    div

  @removeFlashMessage: ->
    existing = document.querySelector('.flash-message')
    existing.remove() if existing

FlashMessage.prepareFlashMessage()