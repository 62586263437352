import {element_with_class, element_with_content} from 'components/common_elements'
import {form_params} from 'components/forms'
import {find_parent_element} from 'lib/dom_search'
import {xhr} from 'lib/requests'
import {encode_html} from 'lib/character_encoder'

class AnalystFlagSelectionDialog
  constructor: (@select) ->
    @showDialog()

  showDialog: ->
    @removePopup()
    document.body.append @buildPopup()

  buildPopup: ->
    div = element_with_class('div', 'overlay')
    opacity = element_with_class('div', 'overlay-opacity')
    opacity.addEventListener 'click', => @removePopup()
    div.append opacity
    div.append element_with_content('div', @dialogContent(), 'overlay-content')
    div

  dialogContent: ->
    div = element_with_content('div', @titleRow(), 'analyst-flag-dialog')
    div.append option for option in @flagOptions()
    div

  titleRow: ->
    div = element_with_class('div', 'row', 'title')
    div.append(element_with_content('div', 'Analyst Flag'))
    div.append(element_with_content('div', 'Algo Removes Forecasts'))
    div.append(element_with_content('div', 'Algo Attempts Forecasts'))
    div.append(element_with_content('div', 'Forecasts'))
    div

  flagOptions: ->
    [
      @buildOption(null, 'none',                                    'No',  'Expected',      'Yes'),
      @buildOption(1, "Acquired/Merged",                            'Yes', 'Not Permitted', 'No'),
      @buildOption(2, "Ceased Trading",                             'Yes', 'Not Permitted', 'No'),
      @buildOption(8, "Takeover In Progress (dividend maintained)", 'No',  'Expected',      'Yes'),
      @buildOption(9, "Takeover In Progress (dividend cut)",        'Yes', 'Not Permitted', 'No'),
      @buildOption(3, "No Pattern",                                 'No',  'Not Permitted', 'Yes'),
      @buildOption(4, "Dividend Cut",                               'Yes', 'Not Permitted', 'No'),
      @buildOption(7, "No Dividends",                               'No',  'Not Permitted', 'Yes'),
      @buildOption(6, "Insufficient History",                       'No',  'Not Permitted', 'Yes'),
      @buildOption(10,"Winding Up",                                 'Yes', 'Up to 3 month ahead', 'No')
    ]

  buildOption: (option, label, existing, forecasts, attempt) ->
    div = element_with_class('div', 'row')
    div.append(element_with_content('div', label))
    div.append(element_with_content('div', existing))
    div.append(element_with_content('div', attempt))
    div.append(element_with_content('div', forecasts))
    div.addEventListener 'click', => @selectOption(option)
    div.classList.add('selected') if option == parseInt(@select.value)
    div

  selectOption: (option) ->
    @select.value = option
    @submitForm()
    @removePopup()

  submitForm: ->
    form = find_parent_element(@select, 'FORM')
    xhr form.getAttribute('action'), 'POST', form_params(form), (response) => response.json().then (json) -> 

  removePopup: ->
    overlay = document.querySelector('.overlay')
    overlay.remove() if overlay

document.addEventListener "DOMContentLoaded", ->
  select = document.querySelector('#share_analyst_flag')
  if select
    select.addEventListener 'mousedown', (e) ->
      e.preventDefault()
      if !select.disabled
        select.blur()
        new AnalystFlagSelectionDialog(select)

export set_analyst_flag_status = (json) ->
  content = "Reviewed <span>#{encode_html json.flag_set_at}</span> by <span>#{encode_html(json.flag_set_by or 'the algorithm')}</span>"
  document.querySelector('.analyst-flag-summary').innerHTML = content