import Litepicker from 'litepicker'
import {DateTime} from "luxon"
import {add_listener_once} from 'lib/interactions'

export add_date_pickers = ->
  [].map.call document.querySelectorAll('.date-picker'), (el) -> add_listener_once(el, -> addDatePicker(el))
  
export addDatePicker = (element) ->
  picker = new Litepicker
    element: element
    format:
      parse: (date) ->
        return date if date instanceof Date
        return parse_date(date) if typeof date == 'string'
        return new Date(date) if typeof date == 'number'
        return new Date()
      output: (date) ->
        return DateTime.fromJSDate(date).toFormat('dd MMMM yyyy')
    dropdowns:
      months: true
      years: true
      minYear: 2000
      maxYear: 2036

parse_date = (date) ->
  r = DateTime.fromFormat(date, 'yyyy-MM-dd')
  r = DateTime.fromFormat(date, 'dd MMMM yyyy') if r.invalidReason == 'unparsable'
  r.toJSDate() 